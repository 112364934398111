import LoadingOverlay from '@/components/modal/LoadingOverlay';
import { osName } from '@/constant/osName';
import {
  clearCookiesAndLocalStorage,
  EAuthCookiesKey,
  EUserIdTypeKeys,
  EUserInforTypeKeys,
} from 'common/authFunction';
import Cookies from 'js-cookie';
import { createContext, useEffect, useState } from 'react';
import { getUserAgent } from '../../apis/user.api';
import { clientSideAvailable } from '../../helpers/checkClientSideAvailable';
import { checkNullish } from '../../helpers/checkNullish';
import { IAuthContext } from '../../interfaces/context.interface';
import { UNSUPPORTED_CONTENT } from '../../pages';
import { useModal } from '../modal-provider/ModalProvider';
export const ErrorLogo = '/box2.svg';

const defaultValue: IAuthContext = {
  authenticated: false,
  login: () => undefined,
  logOut: () => undefined,
  userEmail:
    (clientSideAvailable() &&
      checkNullish(Cookies.get(EUserInforTypeKeys.Email))) ||
    '',
  setUserEmail: (_userEmail: string, _rememberLogin?: boolean) => undefined,
  digitCode: '',
  setDigitCode: () => undefined,
  loading: false,
  setLoading: () => undefined,
  isLoggedIn: () => false,
  warningUserAgentModal: false,
  setWarningUserAgentModal: () => undefined,
};

const AuthContext = createContext<IAuthContext>(defaultValue);

export const AuthProvider: React.FC = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(
    defaultValue.authenticated
  );
  const [userEmail, setUserEmail] = useState(defaultValue.userEmail);
  const [digitCode, setDigitCode] = useState('');
  const [loading, setLoading] = useState(defaultValue.loading);
  const [warningUserAgentModal, setWarningUserAgentModal] = useState(
    defaultValue.warningUserAgentModal
  );
  const login = () => setAuthenticated(true);
  const logOut = () => setAuthenticated(false);

  const { toast } = useModal();

  const isLoggedIn = () => {
    if (
      !!checkNullish(Cookies.get(EAuthCookiesKey.Token)) &&
      !!checkNullish(Cookies.get(EUserIdTypeKeys.Qbank))
    )
      return true;

    clearCookiesAndLocalStorage();
    return false;
  };

  const handleCheckUserAgent = (userAgent: { name: string; os: string }) => {
    if (
      (userAgent.os === osName.iOS && userAgent.name !== 'Mobile Safari') ||
      (userAgent.os === osName.Android && userAgent.name === 'Facebook')
    ) {
      toast.error({
        content: UNSUPPORTED_CONTENT,
        infinite: true,
        image: ErrorLogo,
      });
    }
  };

  useEffect(() => {
    const getUA = async () => {
      try {
        const userAgent = await getUserAgent();
        localStorage.setItem('os', userAgent.data.osName);
        if (userAgent) {
          // console.log(userAgent);
          handleCheckUserAgent({
            name: userAgent.data.clientName,
            os: userAgent.data.osName,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    getUA();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        login,
        logOut,
        userEmail,
        setUserEmail,
        setDigitCode,
        digitCode,
        loading,
        setLoading,
        isLoggedIn,
        setWarningUserAgentModal,
        warningUserAgentModal,
      }}
    >
      {children}
      <LoadingOverlay isVisible={loading} />
    </AuthContext.Provider>
  );
};

export default AuthContext;
