import { checkNullish } from '@/helpers/checkNullish';
import { GetServerSidePropsContext } from 'next';

export const getCookieFromServer = (
  context: GetServerSidePropsContext,
  key: string
) => {
  const value = checkNullish(context?.req.cookies[key]);

  return value;
};
