import {
  IChangePassword,
  IUpdateUserInfoData,
  IUserDetailResponse,
  IVerifyAccountDataType,
} from '@/interfaces/user.interface';
import { AxiosRequestConfig } from 'axios';
import { QueryFunctionContext } from 'react-query';
import { IPaginationResponse } from '../../interfaces/common.interface';
import { IOutstandingBizProfile } from '../../interfaces/profile.interface';
import { tmsHTTPRequest } from '../../services/axios/httpRequest';

export const getUserIdByEmail = (email: string) => {
  return tmsHTTPRequest.get<string>(`/users/id?email=${email}`);
};

export const getCurrentUser = async (config?: AxiosRequestConfig) => {
  return tmsHTTPRequest.get<IUserDetailResponse>(`/users`, config);
};

export const getOutstandingBizProfile = ({
  queryKey,
  pageParam = 1,
}: QueryFunctionContext) => {
  return tmsHTTPRequest.get<IPaginationResponse<IOutstandingBizProfile>>(
    `/users/biz-profiles?pageSize=${queryKey[1]}&pageNumber=${pageParam}`
  );
};
export const putChangePassword = async (data: IChangePassword) => {
  return tmsHTTPRequest.put<string>('/users/change-password', data);
};

export const verifyAccount = async (data: IVerifyAccountDataType) => {
  const formdata = new FormData();
  formdata.append('FullName', data.FullName);
  formdata.append('PhoneNumber', data.PhoneNumber);
  formdata.append('Province', data.Province);
  formdata.append('District', data.District);
  data.CertificateImages.forEach((image) => {
    formdata.append('CertificateImages', image);
  });

  return tmsHTTPRequest.post<string>('/users/verify-data', formdata, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const putUpdateUserInfo = (data: IUpdateUserInfoData) => {
  //cause value must is string (not empty) so must set value is "null", then check here
  if (data.academicRank === 'null') {
    data.academicRank = null;
  }

  return tmsHTTPRequest.put(`/users/info`, data);
};

export const putSetPasswordOnce = (data: {
  password: string;
  confirmPassword: string;
}) => tmsHTTPRequest.put<string>(`/users/set-initial-password`, data);
