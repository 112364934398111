import { NOTIFICATIONS } from '@/constant/QueryKeyName';
import { getAccessToken } from 'common/authFunction';
import { PATH_NAME } from 'configs/pathName';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import io, { Socket } from 'socket.io-client';
import { ISocketContext } from '../interfaces/context.interface';

const BASE_URL = process.env.DOCCEN_API_URL || 'http://localhost:3001';

export const MAX_RECONNECT_SOCKET_TIMES = 3;

export const defautContext: ISocketContext = {
  socket: undefined,
  handleConnect: () => {},
  haveNoti: false,
  setHaveNoti: () => undefined,
  errorHandler: {
    onError() {},
  },
  isSocketConnected: false,
};

export const SocketContext = createContext<ISocketContext>(defautContext);

const SocketsProvider = (props: any) => {
  const [haveNoti, setHaveNoti] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const refetch = () => queryClient.refetchQueries([NOTIFICATIONS]);

  const router = useRouter();

  const [socket, setSocket] = useState<Socket | null>(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  const errorHandler = useMemo(() => {
    return {
      onError(_err?: string) {},
    };
  }, []);

  const handleConnect = () => {
    const socket = io(BASE_URL, {
      transports: ['polling', 'websocket'],
      autoConnect: false,
      reconnection: true,
      reconnectionAttempts: MAX_RECONNECT_SOCKET_TIMES,
      extraHeaders: {
        Authorization: `Bearer ${getAccessToken() || ''}`,
      },
    });
    setSocket(socket);

    bindSocketEvent(socket);
    socket.connect();
  };

  const bindSocketEvent = (socket: Socket) => {
    if (!socket) return;

    socket.on('connect', () => {
      setIsSocketConnected(true);
    });

    socket.on('connect_error', () => {});

    socket?.on('receive-follow', () => {
      refetch();
      setHaveNoti(true);
      localStorage.setItem('haveNoti', 'true');
    });

    socket?.on('unicast-accept-follow', () => {
      refetch();
      setHaveNoti(true);
      localStorage.setItem('haveNoti', 'true');
    });

    socket?.on('notification', () => {
      refetch();
      setHaveNoti(true);
      localStorage.setItem('haveNoti', 'true');
    });

    socket?.on('on-error', (data) => {
      errorHandler.onError(data.err as string);
    });
  };

  useEffect(() => {
    const accessToken = getAccessToken();

    const excludeRoute = [PATH_NAME.AI, PATH_NAME.AI_DETAIL_CONVERSATION];

    if (accessToken && !excludeRoute.includes(router.pathname)) handleConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        haveNoti,
        setHaveNoti,
        handleConnect,
        errorHandler,
        isSocketConnected,
      }}
      {...props}
    />
  );
};

export const useSockets = () => useContext(SocketContext);

export default SocketsProvider;
