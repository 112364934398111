import { useEffect, useRef } from 'react';
import * as THREE from 'three';

const LoadingOverlay = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div
      className={`${
        isVisible ? 'block' : 'hidden'
      } fixed w-screen h-screen left-0 top-0 right-0 flex justify-center items-center z-[10000]`}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000000000,
      }}
    >
      <LoadingAnimation />
    </div>
  );
};

interface ILoadingAnimationProps {
  size?: number;
}

export const LoadingAnimation: React.FunctionComponent<ILoadingAnimationProps> = ({size = 250} : ILoadingAnimationProps) => {
  const wrap = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!wrap.current) {
      return;
    }
    let $wrap = wrap.current;
    var canvassize = size,
      length = 60,
      radius = 11.2,
      rotatevalue = 0.14,
      acceleration = 0,
      animatestep = 0,
      toend = false,
      pi2 = Math.PI * 2,
      group = new THREE.Group(),
      mesh: any,
      camera: any,
      scene: any,
      renderer: any;

    camera = new THREE.PerspectiveCamera(65, 1, 1, 10000);
    camera.position.z = 150;

    scene = new THREE.Scene();
    scene.add(group);

    mesh = new THREE.Mesh(
      new THREE.TubeGeometry(
        new (THREE.Curve.create(
          function () {},
          function (percent: number) {
            var x = length * Math.sin(pi2 * percent),
              y = radius * Math.cos(pi2 * 3 * percent),
              z,
              t;

            t = (percent % 0.25) / 0.25;
            t = (percent % 0.25) - (2 * (1 - t) * t * -0.0185 + t * t * 0.25);
            if (
              Math.floor(percent / 0.25) == 0 ||
              Math.floor(percent / 0.25) == 2
            ) {
              t *= -1;
            }
            z = radius * Math.sin(pi2 * 2 * (percent - t));

            return new THREE.Vector3(x, y, z);
          }
        ) as any)(),
        200,
        1.1,
        2,
        true
      ),
      new THREE.MeshBasicMaterial({
        color: 0x3cacdd,
      })
    );
    group.add(mesh);

    // fake shadow
    (function () {
      var plain, i;
      for (i = 0; i < 10; i++) {
        plain = new THREE.Mesh(
          new THREE.PlaneGeometry(length * 2 + 1, radius * 3, 1),
          new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
          })
        );
        plain.position.z = -2.5 + i * 0.5;
        group.add(plain);
      }
    })();

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(canvassize, canvassize);

    $wrap.appendChild(renderer.domElement);

    animate();

    function render() {
      var progress;

      animatestep = Math.max(
        0,
        Math.min(240, toend ? animatestep + 1 : animatestep - 4)
      );
      acceleration = easing(animatestep, 0, 1, 240);

      if (acceleration > 0.35) {
        progress = (acceleration - 0.35) / 0.65;
        group.rotation.y = (-Math.PI / 2) * progress;
        group.position.z = 50 * progress;
        progress = Math.max(0, (acceleration - 0.97) / 0.03);
        mesh.material.opacity = 1 - progress;
      }

      renderer.render(scene, camera);
    }

    function animate() {
      mesh.rotation.x += rotatevalue + acceleration;
      render();
      requestAnimationFrame(animate);
    }

    function easing(t: number, b: number, c: number, d: number) {
      if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
      return (c / 2) * ((t -= 2) * t * t + 2) + b;
    }
  }, []);

  return <div ref={wrap}></div>;
};

export default LoadingOverlay;
