import { IModalWarning } from '../../../interfaces/components/modal.interface';

const WarningModal = ({
  title,
  content,
  deleteTitle,
  cancelTitle,
  isVisible,
  toggle,
  onDelete,
  onCancel,
}: IModalWarning) => {
  const closeModal = (event: any) => {
    toggle && toggle();
    event.preventDefault();
  };

  return (
    <div
      className={`${
        isVisible ? 'flex' : 'hidden'
      } justify-center items-center fixed left-0 right-0 top-0 bottom-0 z-[1400]`}
    >
      <div
        onClick={closeModal}
        className="bg-black opacity-40 w-full h-full"
      ></div>
      <div className={`max-w-xs bg-white rounded-2xl top-2/5  absolute`}>
        <div className="py-5 px-4 z-10 relative ">
          <h2 className="text-red-500">{title}</h2>
          <div className="text-base text-left text-gray-600 font-light mt-2 ">
            {content}
          </div>
          <section className="flex justify-end items-center gap-5 mt-6">
            <button
              className="text-red-500 bg-white capitalize"
              onClick={(event) => {
                if (onDelete) onDelete();
                closeModal(event);
              }}
            >
              {deleteTitle}
            </button>
            <button
              className="text-black capitalize"
              onClick={(event) => {
                onCancel && onCancel();
                closeModal(event);
              }}
            >
              {cancelTitle}
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
