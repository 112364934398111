import {
  IAuthResponse,
  IRefreshTokenRequest,
  IReSendDigitCode,
  ISendCodeForgotPassword,
  ISendGoogleToken,
  ISetNewPassword,
  IVerifyCode,
  LoginInput,
  loginOtpInput,
  RegisterInput,
} from '@/interfaces/auth.interface';
import { setAuthCookies, setUserInfoCookies } from 'common/authFunction';
import { tmsHTTPRequest } from 'services/axios/httpRequest';

export const postRegister = (data: RegisterInput) =>
  tmsHTTPRequest.post<string>('/auth/sign-up', data);

export const postLogin = (data: LoginInput) =>
  tmsHTTPRequest.post<IAuthResponse>('/auth/login', data).then((res) => {
    setAuthCookies(res);
    setUserInfoCookies(res);
    return res;
  });

export const postLoginOtp = (data: loginOtpInput) =>
  tmsHTTPRequest.post<IAuthResponse>('/auth/otp-login', data).then((res) => {
    if (res) {
      setAuthCookies(res);
      setUserInfoCookies(res);
    }
    return res;
  });

export const postGoogleLogin = (data: ISendGoogleToken) =>
  tmsHTTPRequest.post<IAuthResponse>('/auth/google-login', data).then((res) => {
    setAuthCookies(res);
    setUserInfoCookies(res);
    return res;
  });

/** This unit function use internal data, should be use in client-side */
export const postRefreshToken = (data: IRefreshTokenRequest) =>
  tmsHTTPRequest
    .post<IAuthResponse>('/auth/refresh-token', data)
    .then((res) => {
      setAuthCookies(res);
      return res;
    });

export const postConfirmCode = (data: IVerifyCode) =>
  tmsHTTPRequest.post<string>('/auth/confirm-code', data);

export const postResendCode = (data: IReSendDigitCode) =>
  tmsHTTPRequest.post<string>('/auth/resend-code', data);

/**Send a OTP to process forget password */
export const postForgetPassword = (data: ISendCodeForgotPassword) =>
  tmsHTTPRequest.post<string>('/auth/forgot-password', data);

export const postSetNewPassword = (data: ISetNewPassword) =>
  tmsHTTPRequest.post<string>('/auth/reset-password', data);

export const postExternalLogin = ({ token }: { token: string }) =>
  tmsHTTPRequest.post<IAuthResponse>('/auth/external-login', { token });
