import { createTheme, SxProps, Theme, ThemeProvider } from '@mui/material';
import React from 'react';

export type SxTypes = SxProps<Theme>;

declare module '@mui/material/Button' {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    high_emphasis_v2: true;
    medium_emphasis_v2: true;
    rounded_medium_emphasis_v2: true;
    low_emphasis_v2: true;
    high_emphasis_v1: true;
    medium_emphasis_v1: true;
    low_emphasis_v1: true;
  }
}

const theme = createTheme({
  palette: {
    primary: { main: '#009CE0', contrastText: '#fdfefd' },
    secondary: {
      main: '#e16b56',
      contrastText: '#fdfefd',
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: '15px',
          paddingBottom: '15px',
          '&.Mui-disabled': {
            color: '#A8A8A8 !important',
            backgroundColor: '#EBEBEB !important',
          },
        },
        icon: {
          right: '0px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '400px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '8px 10px',
          '& .MuiButton-startIcon': {
            marginRight: '8px',
          },
          ':disabled': {
            color: '#A8A8A8 !important',
          },
        },
      },
      variants: [
        {
          props: { variant: 'high_emphasis_v2' },
          style: {
            backgroundColor: '#009CE0 !important',
            borderRadius: '100px',
            color: '#FFFFFF',
            ':hover': {
              backgroundColor: '#0086C0 !important',
            },
            '.MuiTouchRipple-root': {
              color: '#0086C9 !important',
            },
            ':disabled': {
              backgroundColor: '#EBEBEB !important',
            },
          },
        },
        {
          props: { variant: 'medium_emphasis_v2' },
          style: {
            backgroundColor: '#FFFFFF !important',
            borderRadius: '100px',
            border: '1px solid #D3DFE7',
            color: '#009CE0',
            ':hover': {
              backgroundColor: '#EEEEEE !important',
            },
            '.MuiTouchRipple-root': {
              color: '#C6E9FA !important',
            },
            ':disabled': {
              backgroundColor: '#EBEBEB !important',
            },
          },
        },
        {
          props: { variant: 'rounded_medium_emphasis_v2' },
          style: {
            backgroundColor: '#FFFFFF !important',
            borderRadius: '100px',
            border: '1px solid #D3DFE7',
            padding: '8px',
            minWidth: '0px',
            color: '#009CE0',
            ':hover': {
              backgroundColor: '#EEEEEE !important',
            },
            '.MuiTouchRipple-root': {
              color: '#C6E9FA !important',
            },
            ':disabled': {
              backgroundColor: '#EBEBEB !important',
            },
          },
        },
        {
          props: { variant: 'low_emphasis_v2' },
          style: {
            backgroundColor: 'transparent !important',
            borderRadius: '100px',
            color: '#009CE0',
            ':hover': {
              backgroundColor: '#EEEEEE !important',
            },
            '.MuiTouchRipple-root': {
              backgroundColor: 'transparent !important',
              color: '#C6E9FA !important',
            },
            ':disabled': {
              backgroundColor: 'transparent !important',
            },
          },
        },
        {
          props: { variant: 'high_emphasis_v1', color: 'primary' },
          style: {
            backgroundColor: '#3CACDD !important',
            borderRadius: '8px',
            color: '#FFFFFF',
            ':hover': {
              backgroundColor: '#0086C0 !important',
            },
            '.MuiTouchRipple-root': {
              color: '#FFFFFF4D !important',
            },
            ':disabled': {
              backgroundColor: '#EBEBEB !important',
            },
          },
        },
        {
          props: { variant: 'high_emphasis_v1', color: 'secondary' },
          style: {
            backgroundColor: '#4FA03B !important',
            borderRadius: '8px',
            color: '#FFFFFF',
            ':hover': {
              backgroundColor: '#4AAE31 !important',
            },
            '.MuiTouchRipple-root': {
              color: '#266F14 !important',
            },
          },
        },
        {
          props: { variant: 'medium_emphasis_v1' },
          style: {
            backgroundColor: '#B1E7FF4D !important',
            borderRadius: '8px',
            border: '1px solid #009FE4',
            color: '#009CE0 !important',
            ':hover': {
              backgroundColor: '#009FE499 !important',
              color: '#FFFFFF !important',
            },
            ':active': {
              color: '#009CE0 !important',
              backgroundColor: '#B1E7FF4D !important',
            },
            '.MuiTouchRipple-root': {
              color: '#009CE0 !important',
            },
            ':disabled': {
              backgroundColor: '#EBEBEB !important',
              borderColor: 'transparent !important',
            },
          },
        },
        {
          props: { variant: 'low_emphasis_v1' },
          style: {
            backgroundColor: 'transparent !important',
            borderRadius: '8px',
            color: '#009CE0',
            ':hover': {
              backgroundColor: '#E9E9E9 !important',
            },
            ':active': {
              backgroundColor: 'transparent !important',
            },
            '.MuiTouchRipple-root': {
              color: '#A4DCF3 !important',
            },
            ':disabled': {
              backgroundColor: 'transparent !important',
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '& .MuiButton-startIcon': {
            marginRight: '8px',
            marginLeft: '0px',
          },
          ':disabled': {
            color: '#A8A8A8 !important',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderColor: '#BFBFBF',
          backgroundColor: '#F1F1F1',
          color: '#242424',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '140%',
          borderRadius: '8px !important',
          border: '1px solid transparent',
          minHeight: '48px',
          '.Mui-focused': {
            color: '#242424 !important',
          },
          ':hover': {
            backgroundColor: '#E9E9E9',
          },
          '&::before, ::after': {
            display: 'none',
          },
          '&.Mui-focused': {
            borderColor: '#009FE4',
          },
          '&.Mui-error': {
            color: '#242424',
            borderColor: '#FF6565',
          },
          '.Mui-disabled': {
            backgroundColor: '#EBEBEB',
            color: '#A8A8A8 !important',
          },
        },
        input: {
          paddingTop: '15px !important',
          borderRadius: 'inherit',
          '&.MuiInputBase-inputAdornedStart': {
            marginLeft: '12px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          color: '#707070',
          '&.Mui-error, &.Mui-focused': {
            color: '#707070',
          },
          '&[data-shrink="true"]': {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            transform: 'translate(12px, 4px) scale(0.75)',
          },
          '&[data-shrink="false"]': {
            transform: 'translate(12px, 13px) scale(1)',
          },

          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '140%',
          '.MuiFormLabel-asterisk': {
            color: '#EE3939',
          },
          letterSpacing: '-0.2px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiFormHelperText-root': {
            margin: '0px 16px 0px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: '18px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          padding: 0,
          '.MuiStepIcon-root': {
            '&.Mui-active': {
              color: '#009CE0',
            },
          },
          '&.Mui-disabled': {
            '.MuiStepIcon-root': {
              color: '#F1F1F1',
              '.MuiStepIcon-text': {
                fill: '#009CE0',
              },
            },
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: '#7C7C7C',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
    },
  },
});

export const CustomThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
