import { IPaginationMeta } from '../interfaces/common.interface';

/* eslint-disable no-unused-vars */
export const AVT_SIZE = 180;
export const MAX_CANVAS_SIZE = 2500; //do not exceed this number, due to problems in canvasizing the image in avatar component
export const SHOW_HEADER_ON_SCROLLING_POINT = 20;
export const BIZ_SHOW_HEADER_ON_SCROLLING_POINT = 290;
export const NAV_ICON_SIZE = 18;
export const NAV_COIN_ICON_SIZE = 14;
export const NAV_CLOUD_STORAGE_SIZE = 40;
export const NAV_AVATAR_SIZE = 40;
export const NAV_BELL_ICON_SIZE = 24;
export const RESULT_PER_PAGE = 10;
export const STALE_TIME = 20000;
export const CACHE_TIME = 10;
export const MAX_INVITE_FRIEND = 3;
export const MAX_DAILY_CHALLENGE = 7;
export const FILES_PER_ROW = 6;
export const MAX_IMAGE_WIDTH_AND_HEIGHT = 2048;
export const getMaxFileRows = (numFilesPerRow: number) => {
  if (numFilesPerRow === 1) return FILES_PER_ROW;
  if (numFilesPerRow === 2) return 3;
  if (numFilesPerRow === 3 || numFilesPerRow === 4) return 2;
  return 1;
};
export const FOLDERS_PER_ROW = 8;
export const getMaxFolderRows = (numFoldersPerRow: number) => {
  if (numFoldersPerRow === 1) return 4;
  if (numFoldersPerRow === 2) return 3;
  return 2;
};
export const MEMBERS_PER_PAGE = 4;
export const COURSE_PER_ROW = 4;
export enum FILE_TYPE {
  Image = 'Image',
  Other = 'Other',
}

export const GROUP_POST_PAGE_SIZE = 10;
export const FEED_SEARCH_GROUP_PAGE_SIZE = 5;
export const FEED_SEARCH_GROUP_PAGE_SIZE_DESKTOP = 10;

export const FEED_SEARCH_FRIEND_PAGE_SIZE = 10;
export const FEED_SEARCH_POST_PAGE_SIZE = 4;
export const FEED_USER_WALL_PAGE_SIZE = 10;

export const GROUP_SEARCH_MEMBER_PAGE_SIZE = 10;
export const GROUP_SEARCH_POST_PAGE_SIZE = 4;
export const GROUP_SEARCH_DOCUMENT_PAGE_SIZE = 10;
export const GROUP_SEARCH_COURSE_PAGE_SIZE = 10;

export const DEFAULT_PAGINATION_META: IPaginationMeta = {
  currentPage: 0,
  totalPages: 0,
  totalCount: 0,
  pageSize: 0,
  hasPreviousPage: false,
  hasNextPage: false,
};
