import Image from 'next/image';
import { useEffect } from 'react';

const ErrorLogo = '/red-failed.png';
const SuccessLogo = '/pinkcheck.svg';

type Props = {
  image?: any;
  content?: any;
  isVisible?: boolean | string;
  toggle?: Function;
  classname?: string;
  type?: string;
  duration?: number;
  infinite?: boolean;
};

export const NewModal = ({
  image,
  content,
  isVisible,
  toggle,
  classname,
  type,
  duration = 1000,
  infinite = false,
}: Props) => {
  const closeModal = (event: any) => {
    toggle && toggle();

    event.preventDefault();
  };

  let src: string = '';

  switch (type) {
    case 'error':
      src = ErrorLogo;
      break;
    case 'success':
      src = SuccessLogo;
      break;
    default:
      src = SuccessLogo;
      break;
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (isVisible && !infinite) {
        toggle && toggle();
      }
    }, duration);

    return () => clearTimeout(timeOutId);
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`${
        isVisible ? 'flex' : 'hidden'
      } justify-center items-center fixed left-0 right-0 top-0 bottom-0 z-[1400]`}
    >
      <div
        onClick={closeModal}
        className="bg-black opacity-40 w-full h-full"
      ></div>
      <div
        className={`${classname} max-w-xs min-w-[20%] flex flex-col pb-5 px-7 bg-white rounded-2xl top-2/5 absolute justify-center items-center`}
      >
        <div className="p-5 space-y-6 z-10 relative ">
          <div className="flex justify-center">
            <Image src={image || src} width={112} height={112} alt="gift_box" />
          </div>

          <div className="text-base text-center w-full text-black">
            {content}
          </div>
        </div>
        <button
          className="btn blue-gradient max-w-[90%]"
          onClick={(event) => closeModal(event)}
        >
          Đóng
        </button>
      </div>
    </div>
  );
};
