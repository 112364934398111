import { clientSideAvailable } from '@/helpers/checkClientSideAvailable';
import { checkNullish } from '@/helpers/checkNullish';
import { IAuthResponse } from '@/interfaces/auth.interface';
import Cookies from 'js-cookie';
import { GetServerSidePropsContext } from 'next';
import { PATH_NAME } from '../configs/pathName';
import { getCookieFromServer } from './cookiesCommon';

export enum EAuthCookiesKey {
  Token = 'accessToken',
  Refresh = 'refreshToken',
}

export enum EUserIdTypeKeys {
  Qbank = 'userId',
  Tms = 'tms_userId',
}

export enum EUserInforTypeKeys {
  Email = 'userEmail',
}

/** This is a legacy function, use only to makesure every user remove this cookies, dont know when to finish that process :D */
export const clearTMSCookies = () => {
  Cookies.remove('tms_accessToken');
  Cookies.remove('tms_refreshToken');
};

/**
 *
 * @param context should provide this in case you get cookie from server
 */
export function getAccessToken(
  context?: GetServerSidePropsContext
): string | null {
  let accessToken = null;

  if (clientSideAvailable()) {
    accessToken = checkNullish(Cookies.get(EAuthCookiesKey.Token));
  } else {
    if (context) {
      accessToken = getCookieFromServer(
        context as GetServerSidePropsContext,
        EAuthCookiesKey.Token
      );
    }
  }

  return accessToken;
}

/**
 *
 * @param context should provide this in case you get cookie from server
 */
export function getRefreshToken(
  context?: GetServerSidePropsContext
): string | null {
  let refreshToken = null;
  if (clientSideAvailable()) {
    refreshToken = checkNullish(Cookies.get(EAuthCookiesKey.Refresh));
  } else {
    if (context) {
      refreshToken = getCookieFromServer(
        context as GetServerSidePropsContext,
        EAuthCookiesKey.Refresh
      );
    }
  }
  return refreshToken;
}

/**
 *
 * @param context should provide this in case you get cookie from server
 */
export function getUserId(context?: GetServerSidePropsContext): string | null {
  let userId = null;
  if (clientSideAvailable()) {
    userId = checkNullish(Cookies.get(EUserIdTypeKeys.Qbank));
  } else {
    userId = getCookieFromServer(
      context as GetServerSidePropsContext,
      EUserIdTypeKeys.Qbank
    );
  }
  return userId;
}

export const setAuthCookies = (
  props: IAuthResponse,
  _context?: GetServerSidePropsContext
) => {
  const { token, refreshTokenExpiryTime, refreshToken } = props;
  Cookies.set(EAuthCookiesKey.Token, token, {
    // use this so that token can keep longer, then use this with refresh to get new token
    expires: new Date(refreshTokenExpiryTime),
  });
  Cookies.set(EAuthCookiesKey.Refresh, refreshToken, {
    expires: new Date(refreshTokenExpiryTime),
  });
};

export const setUserInfoCookies = (props: IAuthResponse) => {
  Cookies.set(EUserIdTypeKeys.Qbank, props.qBankUserId.toString(), {
    expires: new Date(props.refreshTokenExpiryTime),
  });
  Cookies.set(EUserIdTypeKeys.Tms, props.userId.toString(), {
    expires: new Date(props.refreshTokenExpiryTime),
  });
  Cookies.set(EUserInforTypeKeys.Email, props.email, {
    expires: new Date(props.refreshTokenExpiryTime),
  });
};

export const serverSideIsLoggedIn = (context: GetServerSidePropsContext) => {
  const userId = getUserId(context);
  const accessToken = getAccessToken(context);

  return {
    isLoggedIn: !!getAccessToken(context) && !!getUserId(context),
    accessToken,
    userId,
  };
};

export const clearCookiesAndLocalStorage = (
  _context?: GetServerSidePropsContext
) => {
  Cookies.remove(EAuthCookiesKey.Token);
  Cookies.remove(EAuthCookiesKey.Refresh);

  Cookies.remove(EUserIdTypeKeys.Qbank);
  Cookies.remove(EUserIdTypeKeys.Tms);

  if (clientSideAvailable()) {
    localStorage.clear();
  }

  // legacy
  Cookies.remove('isVerify');
  Cookies.remove('fullname');
  Cookies.remove('userAvatar');
  clearTMSCookies();
};

export const logout = () => {
  clearCookiesAndLocalStorage();
  window.location.href = PATH_NAME.FEED;
};

export const checkExistsTokens = (): boolean =>
  !!getRefreshToken() && !!getAccessToken();
