import { QueryFunctionContext, QueryKey } from 'react-query';
import { RESULT_PER_PAGE } from '../../constant/numericParams';
import { ISearchReq } from '../../interfaces/search.interface';

export const generateSearchApiQuery = ({
  keyword,
  perPage = RESULT_PER_PAGE,
  pageNumber = 1,
  filterOptions,
}: ISearchReq): string => {
  let apiQuery = `?perPage=${perPage}&pageNumber=${pageNumber}&keyword=${keyword}`;

  if (filterOptions) {
    let option: keyof typeof filterOptions;
    for (option in filterOptions) {
      if (filterOptions[option])
        apiQuery += `&${option}=${filterOptions[option]}`;
    }
  }

  return apiQuery;
};

export const mapQueryKeyToSearchInGroupData = ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<QueryKey>) => {
  return {
    pageNumber: pageParam,
    folderId: queryKey[1],
    keyword: queryKey[2],
    pageSize: queryKey[3],
  };
};
