import Image from 'next/image';
import { NextPageWithLayout } from './page';

export const QUICKSTART_LOGO = '/icon-512x512.png';
export const QUICKSTART_TITLE = '/img/quick-start-title.png';

const Greeting: NextPageWithLayout = () => {
  return (
    <section className="w-screen fixed top-0 left-0 flex flex-col mx-auto justify-center items-center text-center h-screen -my-6">
      <Image
        priority
        src={QUICKSTART_LOGO}
        alt="doccen_logo"
        width={100}
        height={100}
      />
      <Image
        priority
        src={QUICKSTART_TITLE}
        alt="doccen_logo"
        width={100}
        height={20}
        className="my-6 block"
      />
      <p className="text-primary mt-6">
        Doccen.vn | Kết nối cộng đồng y tế Việt Nam
      </p>
    </section>
  );
};

export default Greeting;
Greeting.getLayout = (page) => {
  return <>{page}</>;
};
