import useToast from '@/hooks/useToast';
import { EAuthCookiesKey, EUserIdTypeKeys } from 'common/authFunction';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { postOnLinkClick } from '../apis/tms/share';
import { generateInviteLink } from '../common/redirectToFolderPage';
import { PATH_NAME } from '../configs/pathName';
import { checkNullish } from '../helpers/checkNullish';
import Greeting from './greeting';
import { NextPageWithLayout } from './page';

export const UNSUPPORTED_CONTENT =
  ' Bạn đang không sử dụng trình duyệt được hỗ trợ, rất nhiều chức năng sẽ bị hạn chế. Vui lòng chuyển đổi sang Safari (trên iOS) hoặc Chrome (trên Android) để có trải nghiệm tốt nhất';

const Home: NextPageWithLayout = () => {
  const router = useRouter();
  // const { folder, t } = router.query;
  const toast = useToast();

  const redirectToUserPage = async () => {
    const userId = checkNullish(Cookies.get(EUserIdTypeKeys.Qbank)) || '';
    const accessToken = checkNullish(Cookies.get(EAuthCookiesKey.Token)) || '';
    const { folder } = router.query;
    if (folder) {
      return;
    }

    if (!userId || !accessToken) {
      window.location.href = PATH_NAME.VISITOR_FEED;
      return;
    }
    window.location.href = PATH_NAME.HOME_FEED;
  };

  const receiveShareLink = async () => {
    const { folder } = router.query;
    if (!folder) {
      redirectToUserPage();
      return;
    }
    const accessToken = checkNullish(Cookies.get(EAuthCookiesKey.Token)) || '';

    if (!accessToken) {
      toast('Bạn phải đăng nhập trước khi tham gia nhóm', 'error');
      setTimeout(() => {
        //this fix case when user use browser-in-app (BIA)
        //when user click open in brower like Safari or Chrome, below query will move folder and t query from BIA to current browser
        router.replace(`${PATH_NAME.LOGIN}/?folder=${folder}`);
      }, 450);
      return;
    }

    try {
      const inviteLink = generateInviteLink(folder as string);
      const res: any = await postOnLinkClick(inviteLink);
      toast('Nhận link thành công', 'success');
      setTimeout(() => {
        router.replace(PATH_NAME.MY_GROUPS + '/' + res.folderId);
      }, 450);
    } catch (error) {
      toast('Lỗi: Không nhận link được', 'error');
      setTimeout(() => {
        router.replace('/404');
      }, 450);
    }
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    receiveShareLink();
  }, [router.isReady]);

  return (
    <>
      <Greeting />
    </>
  );
};

export default Home;

Home.getLayout = (page) => {
  return <>{page}</>;
};
