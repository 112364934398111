import { TRoleAccess } from '@/constant/storage';
import { checkNullish } from '@/helpers/checkNullish';
import { EUserIdTypeKeys } from 'common/authFunction';
import Cookies from 'js-cookie';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { mapQueryKeyToSearchInGroupData } from '../../common/search/generateSearchApiQuery';
import { FOLDERS_PER_ROW } from '../../constant/numericParams';
import { tmsHTTPRequest } from '../../services/axios/httpRequest';

export const getFoldersShared = ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<any[], any>) => {
  return tmsHTTPRequest.get(
    `/v1/folders/share-with-me?PageNumber=${pageParam}&PageSize=${
      queryKey[2] || FOLDERS_PER_ROW
    }`
  );
};

export const createShareLink = async (data: {
  folderId: string;
  shareType: string;
}) => {
  return tmsHTTPRequest.post('/v1/shares/create-share-link', data);
};

export const getShareLink = async (folderId: string) => {
  return tmsHTTPRequest.get(`/v1/shares/get-share-link?FolderId=${folderId}`);
};

export const updateAccessExpire = async (data: {
  folderId: string;
  accessExpiredAt: string | null;
}) => {
  return tmsHTTPRequest.put('/v1/shares/update-access-expire', data);
};

export const addMember = async (data: { folderId: string; email: string }) => {
  return tmsHTTPRequest.post('/v1/shares/add-member', data);
};

export const getAllMembers = async (folderId: string) => {
  return tmsHTTPRequest.get(`/v1/shares/get-all-members?FolderId=${folderId}`);
};

export const updatePermission = async (data: {
  folderId: string;
  email: string;
  permission: string;
}) => {
  return tmsHTTPRequest.put('/v1/shares/update-permission', data);
};

export const postOnLinkClick = async (url: string) => {
  return tmsHTTPRequest.post('/v1/shares/on-link-click', {
    shareLink: url,
  });
};

export const getMineRoleOfFolder = (data: { folderId: string }) => {
  return tmsHTTPRequest.get<TRoleAccess>(
    `/v1/shares/permission?FolderId=${data.folderId}&userId=${checkNullish(
      Cookies.get(EUserIdTypeKeys.Tms) ?? null
    )}`
  );
};

export const leaveFolderOrGroups = ({ id }: { id: string }) => {
  return tmsHTTPRequest.delete(`/v1/shares/leave-group/${id}`);
};

export const postSearchMembersInGroup = (
  context: QueryFunctionContext<QueryKey>
) => {
  const data = mapQueryKeyToSearchInGroupData(context);
  return tmsHTTPRequest.post(`/v1/shares/${data.folderId}/search`, data);
};
