import { NextRouter } from 'next/router';
import { UrlObject } from 'url';
import { checkNullish } from './checkNullish';

export const prevPathKey = 'prevPath';

export const routerToBack = (
  router: NextRouter,
  destination: UrlObject | string
) => {
  const prevPath = checkNullish(sessionStorage.getItem(prevPathKey));

  if (!!prevPath && prevPath !== router.asPath) {
    router.back();
    return;
  }
  router.push(destination);
};
