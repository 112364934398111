import { useEffect } from 'react';

/**
This function is used to prevent user open devtool in browser 
*/
export default function usePreventOpenDevtool() {
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I trên Windows/Linux
        (event.metaKey && event.altKey && event.keyCode === 73) || // Command+Option+I trên macOS
        (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J trên Windows/Linux
        (event.metaKey && event.altKey && event.keyCode === 74) || // Command+Option+J trên macOS
        (event.ctrlKey && event.shiftKey && event.keyCode === 67) || // Ctrl+Shift+C trên Windows/Linux
        (event.metaKey && event.altKey && event.keyCode === 67) || // Command+Option+C trên macOS
        (event.ctrlKey && event.shiftKey && event.keyCode === 77) || // Ctrl+Shift+M trên Windows/Linux
        (event.metaKey && event.altKey && event.keyCode === 77) || // Command+Option+M trên macOS
        event.keyCode === 123 //f12
      ) {
        event.preventDefault();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
