import {
  IModalAction,
  IModalContext,
  IModalCTA,
  IModalNotificate,
  IModalWarning,
} from '../../interfaces/components/modal.interface';

export const initModalContextState: IModalContext = {
  toast: {
    warning: () => undefined,
    action: () => undefined,
    cta: () => undefined,
    notificate: () => undefined,
    success: () => undefined,
    error: () => undefined,
  },
  modalVisible: {},
};

export const initWarningModalState: IModalWarning = {
  title: '',
  content: '',
  isVisible: false,
  toggle: () => undefined,
  deleteTitle: '',
  cancelTitle: '',
  onDelete: () => undefined,
};

export const initActionModalState: IModalAction = {
  isVisible: false,
  title: '',
  content: '',
  toggle: () => undefined,
  onAction: () => undefined,
  classname: '',
  actionName: '',
  closeAfterAction: false,
};

export const initCTAModalState: IModalCTA = {
  isVisible: false,
  content: '',
  toggle: () => undefined,
  cta_1_name: '',
  cta_2_name: '',
  closedAfterCTA1: false,
  closedAfterCTA2: false,
  cta_1_action: () => undefined,
  cta_2_action: () => undefined,
  classname: '',
};

export const initNotificateModalState: IModalNotificate = {
  isVisible: false,
  image: '',
  content: '',
  toggle: () => undefined,
  classname: '',
  type: '',
};
