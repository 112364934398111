import { clientSideAvailable } from '@/helpers/checkClientSideAvailable';
import { IAuthResponse } from '@/interfaces/auth.interface';
import { postRefreshToken } from 'apis/tms/auth.api';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  clearCookiesAndLocalStorage,
  getAccessToken,
  getRefreshToken,
} from 'common/authFunction';
import { PATH_NAME } from 'configs/pathName';

const baseApi = process.env.DOCCEN_API_URL
  ? `${process.env.DOCCEN_API_URL}`
  : 'http://localhost:3000';

const requestConfig: AxiosRequestConfig = {
  baseURL: baseApi,
  //https://stackoverflow.com/questions/63064393/getting-axios-error-connect-etimedout-when-making-high-volume-of-calls
};

//global variable, using closure
let getNewAuthenToken: Promise<IAuthResponse> | null = null;

const handleClearStorageAndNavigate = () => {
  clearCookiesAndLocalStorage();

  if (clientSideAvailable()) {
    if (window.location.pathname !== PATH_NAME.VISITOR_FEED)
      window.location.replace(PATH_NAME.VISITOR_FEED);
  }
};

const getNewToken = async (error: AxiosError) => {
  const refreshToken = getRefreshToken();
  const token = getAccessToken();

  // if miss one of token -> throw error immediately and logout user
  if (!refreshToken || !token) {
    clearCookiesAndLocalStorage();
    throw error;
  }

  if (!getNewAuthenToken) {
    //save promise to global variable
    getNewAuthenToken = postRefreshToken({ refreshToken, token });
  }

  try {
    //make all api calling wait this promise
    const data = await (getNewAuthenToken as Promise<IAuthResponse>);

    if (error.config.headers)
      error.config.headers.Authorization = `Bearer ${data.token}`;

    const newAxios = axios.create();
    //clone interceptors.response of the original axios
    newAxios.interceptors.response.use(
      (res) => res.data,
      (err) => {
        const status = err.response?.status;

        //if 401 error means have s.t wrong with new tokens
        if (status === 401) {
          handleClearStorageAndNavigate();
        }

        throw err;
      }
    );

    //recall 401 request with new token have been set to header above
    return newAxios(error.config);
  } catch (_err) {
    handleClearStorageAndNavigate();

    throw error;
  } finally {
    //release global var store promise
    getNewAuthenToken = null;
  }
};

const axiosInstance = axios.create(requestConfig);

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      const accessToken = getAccessToken();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (error) => {
    const statusCode = error.response?.status as number;

    if (statusCode == 401) {
      //check if postRefreshToken api failed with code 401, no new tokens has been returned
      if ((error.config.url as string).includes('/auth/refresh-token')) {
        throw error;
      }
      try {
        return await getNewToken(error);
      } catch (err) {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
