import Script from 'next/script';
import React from 'react';

const GAScript = () => {
  return (
    <>
      <Script
        id="google-ga4-link"
        strategy="lazyOnload"
        src={'https://www.googletagmanager.com/gtag/js?id=G-74SFVXB4ZJ'}
      />
      <Script id="google-ga4-script" strategy="lazyOnload">
        {`
       window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
     
       gtag('config', 'G-74SFVXB4ZJ');
  `}
      </Script>
    </>
  );
};

export default GAScript;
