import { Alert, Snackbar } from '@mui/material';
import { createContext, useMemo, useState } from 'react';
import { generateUUIDUsingMathRandom } from '../../common/generateGUID';
import { Toast, ToastOptions } from './types';

export const SnackBarContext = createContext<{
  toast: (
    content: string | React.ReactNode,
    type: 'success' | 'error',
    options?: ToastOptions
  ) => void;
}>({
  toast: () => {},
});

const SnackBarProvider: React.FC = ({ children }) => {
  const [openToast, setOpenToast] = useState<Toast>({} as Toast);
  const { content, type, id, options } = openToast;
  const {
    duration = 1300,
    position,
    contentButtonClose,
    className,
    ...restProps
  } = options || {};

  const _children = useMemo(() => children, [children]);

  const toast = (
    content: string | React.ReactNode,
    type: 'success' | 'error',
    options?: ToastOptions
  ) => {
    const id = generateUUIDUsingMathRandom();

    setOpenToast({ content, type, id, options });
  };

  const removeToast = () =>
    setOpenToast((prev) => ({ ...prev, content: null }));

  return (
    <SnackBarContext.Provider value={{ toast }}>
      {_children}
      <Snackbar
        key={id}
        anchorOrigin={{
          vertical: position?.vertical || 'top',
          horizontal: position?.horizontal || 'center',
        }}
        autoHideDuration={duration}
        disableWindowBlurListener
        onClose={removeToast}
        open={!!content}
        {...restProps}
      >
        <div>
          {type === 'success' ? (
            <>
              {content && (
                <div
                  className={`${className} bg-black/75 rounded-md p-4 text-white text-base`}
                >
                  {content}
                  {contentButtonClose && contentButtonClose!(removeToast)}
                </div>
              )}
            </>
          ) : (
            <Alert severity="error">{content}</Alert>
          )}
        </div>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
