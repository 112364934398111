import { createContext, useContext, useState } from 'react';
import { ActionModal } from '../../components/modal/toast-modal/ActionModal';
import { CTAModal } from '../../components/modal/toast-modal/CTAModal';
import { NewModal } from '../../components/modal/toast-modal/newModal';
import WarningModal from '../../components/modal/toast-modal/WarningModal2';
import {
  IModalAction,
  IModalContext,
  IModalCTA,
  IModalNotificate,
  IModalState,
  IModalWarning,
} from '../../interfaces/components/modal.interface';
import * as initState from './initState';

const ModalContext = createContext<IModalContext>(
  initState.initModalContextState
);

const ModalProvider: React.FC = ({ children }) => {
  const [modalVisible, setModalVisible] = useState<IModalState>({
    warning: initState.initWarningModalState,
    action: initState.initActionModalState,
    cta: initState.initCTAModalState,
    notificate: initState.initNotificateModalState,
    success: initState.initNotificateModalState,
    error: initState.initNotificateModalState,
  });

  const toggle = (
    type: 'warning' | 'action' | 'cta' | 'notificate' | 'success' | 'error'
  ) => {
    setModalVisible((state) => ({
      ...state,
      [type]: {
        ...state[type],
        isVisible: false,
      },
    }));
  };

  const toast = {
    warning: (props: IModalWarning) => {
      setModalVisible((state) => ({
        ...state,
        warning: {
          isVisible: true,
          toggle: () => toggle('warning'),
          ...props,
        },
      }));
    },
    action: (props: IModalAction) => {
      setModalVisible((state) => ({
        ...state,
        action: {
          isVisible: true,
          toggle: () => toggle('action'),
          ...props,
        },
      }));
    },
    cta: (props: IModalCTA) => {
      setModalVisible((state) => ({
        ...state,
        cta: {
          isVisible: true,
          toggle: () => toggle('cta'),
          ...props,
        },
      }));
    },
    notificate: (props: IModalNotificate) => {
      setModalVisible((state) => ({
        ...state,
        notificate: {
          isVisible: true,
          toggle: () => toggle('notificate'),
          ...props,
        },
      }));
    },
    success: (props: IModalNotificate) => {
      setModalVisible((state) => ({
        ...state,
        notificate: {
          isVisible: true,
          toggle: () => toggle('notificate'),
          type: 'success',
          ...props,
        },
      }));
    },
    error: (props: IModalNotificate) => {
      setModalVisible((state) => ({
        ...state,
        notificate: {
          isVisible: true,
          toggle: () => toggle('notificate'),
          type: 'error',
          ...props,
        },
      }));
    },
  };

  return (
    <ModalContext.Provider value={{ toast, modalVisible }}>
      {children}
      <ActionModal
        isVisible={modalVisible.action.isVisible}
        title={modalVisible.action.title}
        content={modalVisible.action.content}
        toggle={modalVisible.action.toggle}
        action={modalVisible.action.onAction}
        actionName={modalVisible.action.actionName}
        classname={modalVisible.action.classname}
        closeAfterAction={modalVisible.action.closeAfterAction}
      />
      <WarningModal
        title={modalVisible.warning.title}
        content={modalVisible.warning.content}
        isVisible={modalVisible.warning.isVisible}
        toggle={modalVisible.warning.toggle}
        deleteTitle={modalVisible.warning.deleteTitle}
        cancelTitle={modalVisible.warning.cancelTitle}
        onDelete={modalVisible.warning.onDelete}
        onCancel={modalVisible.warning.onCancel}
      />
      <NewModal
        image={modalVisible.notificate.image}
        content={modalVisible.notificate.content}
        isVisible={modalVisible.notificate.isVisible}
        toggle={modalVisible.notificate.toggle}
        classname={modalVisible.notificate.classname}
        type={modalVisible.notificate.type}
        duration={modalVisible.notificate.duration}
        infinite={modalVisible.notificate.infinite}
      />
      <CTAModal
        content={modalVisible.cta.content}
        isVisible={modalVisible.cta.isVisible}
        toggle={modalVisible.cta.toggle}
        classname={modalVisible.cta.classname}
        cta_1_name={modalVisible.cta.cta_1_name}
        cta_1_action={modalVisible.cta.cta_1_action}
        cta_2_name={modalVisible.cta.cta_2_name}
        cta_2_action={modalVisible.cta.cta_2_action}
        closedAfterCTA1={modalVisible.cta.closedAfterCTA1}
        closedAfterCTA2={modalVisible.cta.closedAfterCTA2}
      />
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
