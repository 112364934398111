type Props = {
  content?: any;
  isVisible?: boolean | string;
  toggle?: Function;
  classname?: string;
  cta_1_name: string;
  cta_1_action?: Function;
  cta_2_name: string;
  cta_2_action?: Function;
  closedAfterCTA1?: boolean;
  closedAfterCTA2?: boolean;
};

export const CTAModal = ({
  content,
  isVisible,
  toggle,
  classname,
  cta_1_name,
  cta_1_action,
  cta_2_name,
  cta_2_action,
  closedAfterCTA1 = false,
  closedAfterCTA2 = false,
}: Props) => {
  const closeModal = (event: any) => {
    toggle && toggle();

    event.preventDefault();
  };

  return (
    <div
      className={`${
        isVisible ? 'flex' : 'hidden'
      } justify-center items-center fixed left-0 right-0 top-0 bottom-0 z-[1400]`}
    >
      <div
        onClick={closeModal}
        className="bg-black opacity-40 w-full h-full"
      ></div>
      <div
        className={`${classname} max-w-xs min-w-[20%] flex flex-col pb-5 px-3 bg-white rounded-2xl top-2/5 absolute justify-center items-center`}
      >
        <div className="py-5 px-2 z-10 relative ">
          <p className="text-md font-semibold">{content}</p>
        </div>
        <div className="relative w-full pb-4">
          <div className="absolute right-3">
            <button
              className="text-red-500 mr-8"
              onClick={(event) => {
                cta_1_action && cta_1_action();
                closedAfterCTA1 && closeModal(event);
              }}
            >
              {cta_1_name}
            </button>
            <button
              className=""
              onClick={(event) => {
                cta_2_action && cta_2_action();
                closedAfterCTA2 && closeModal(event);
              }}
            >
              {cta_2_name}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
