type Props = {
  title?: string;
  content?: any;
  isVisible?: boolean | string;
  toggle?: Function;
  classname?: string;
  action: any;
  actionName: string;
  closeAfterAction?: boolean;
};

export const ActionModal = ({
  title,
  content,
  isVisible,
  toggle,
  classname,
  action,
  actionName,
  closeAfterAction = false,
}: Props) => {
  const closeModal = (event: any) => {
    toggle && toggle();

    event.preventDefault();
  };

  return (
    <div
      className={`${
        isVisible ? 'flex' : 'hidden'
      } justify-center items-center fixed left-0 right-0 top-0 bottom-0 z-[1400]`}
    >
      <div
        onClick={closeModal}
        className="bg-black opacity-40 w-full h-full"
      ></div>
      <div
        className={`${classname} max-w-xs min-w-[20%] flex flex-col pb-5 px-3 bg-white rounded-2xl top-2/5 absolute justify-center items-center`}
      >
        <div className="py-5 px-2 z-10 relative ">
          <div className="w-full text-red-500 text-[16px]">{title}</div>
          <div className="w-full text-[#333333] text-[14px]">{content}</div>
        </div>
        <div className="relative w-full pb-4">
          <div className="absolute right-3">
            <button
              className="text-red-500 mr-8"
              onClick={() => {
                action();
                closeAfterAction && toggle && toggle();
              }}
            >
              {actionName}
            </button>
            <button className="" onClick={(event) => closeModal(event)}>
              Hủy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
